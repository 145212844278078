import classNames from 'classnames';
import { useContext, useEffect, useState } from 'react';
import { SpeechConfigContext } from '../providers/speechConfig';
import { TakeawayTextKpiPoint, TakeawayTextValue } from '../../../functions/src/common/types/kpiTypes';
import { useKpiCollection } from '../hooks/useKpiCollection';
import ListeningView from './ListeningView';

export const TakeawayView = () => {
  const speechConfig = useContext(SpeechConfigContext);
  const [latestTakeaway, setLatestTakeaway] = useState<TakeawayTextValue | null>(null);

  const [takeawayKpis] = useKpiCollection<TakeawayTextKpiPoint[]>({
    speechId: speechConfig?.speechId ?? null,
    kpiName: 'Takeaway',
    initialState: [] as TakeawayTextKpiPoint[],
  });

  useEffect(() => {
    setLatestTakeaway(takeawayKpis.at(-1)?.value ?? null);
  }, [takeawayKpis]);

  if (!latestTakeaway) {
    return <ListeningView />;
  }
  return (
    <div className="mt-4">
      <div className="overflow-hidden rounded-xl bg-eidraWhite">
        <div className="flex justify-between bg-partyBgColor px-5 py-3 text-[0.75rem] text-partyColor">
          <strong>Frågan för Sverige</strong>
          <span>{speechConfig?.party}</span>
        </div>
        <div className="grid grid-cols-1">
          <TakeawayItem title={'Sveriges utmaning:'} value={latestTakeaway.problem} highlight={true} />
          <TakeawayItem title={'Vad ska vi göra åt det?'} value={latestTakeaway.solution} highlight={false} />
          <TakeawayItem title={'Hur ska vi göra det?'} value={latestTakeaway.tools} highlight={false} />
        </div>
      </div>
    </div>
  );
};

const TakeawayItem = ({ title, value, highlight }: { title: string; value: string; highlight: boolean }) => {
  const highlightClasses = highlight
    ? 'after:absolute after:inset-0 after:z-0 after:bg-partyBgColor after:opacity-40'
    : null;

  return (
    <div
      className={classNames(
        'relative flex flex-col border border-t-0 border-eidraGrey bg-opacity-40 p-5',
        highlightClasses,
      )}>
      <span className="z-10 text-[1.125rem] leading-[1.3]">{title}</span>
      <strong className="z-10 text-[1.5rem] font-medium leading-[1.2]">{value}</strong>
    </div>
  );
};
