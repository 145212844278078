export function getDateDifferenceInSeconds(start: Date, end: Date) {
  return (end.getTime() - start.getTime()) / 1000;
}

export function formatDate(date: Date) {
  return date.toLocaleDateString('sv-SE', {
    day: 'numeric',
    month: 'long',
    hour: 'numeric',
    minute: 'numeric',
  });
}
