import { useContext } from 'react';
import { SpeechConfigContext } from '../providers/speechConfig';
import { SimpleListeningAnimation } from './SimpleListeningAnimation';
import { isRecording } from '../utils/elapsedTime';

function ListeningView() {
  const speechConfig = useContext(SpeechConfigContext);
  const recording = isRecording(speechConfig);

  return recording ? (
    <div className={'flex h-full flex-col items-center justify-center gap-y-2'}>
      <SimpleListeningAnimation />
      <span className={'text-[.75rem] font-light'}>Lyssnar efter ny information</span>
    </div>
  ) : (
    <div className={'flex h-full flex-col items-center justify-center gap-y-2'}>
      <span className={'text-[.75rem] font-light'}>Inget hittat</span>
    </div>
  );
}

export default ListeningView;
