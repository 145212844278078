import { motion, AnimatePresence } from 'framer-motion';
import { useContext, useMemo } from 'react';
import { SpeechConfigContext } from '../providers/speechConfig';
import { useKpiCollection } from '../hooks/useKpiCollection';
import { SentimentKpiPoint } from '../../../functions/src/common/types/kpiTypes';
import classNames from 'classnames';
import ListeningView from './ListeningView';

type Sentiment = {
  date: number;
  keyword: string;
  sentiment: 'positive' | 'negative';
};

export function SentimentView() {
  const speechConfig = useContext(SpeechConfigContext);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sentimentKpi, loadingPositives, errorPositives] = useKpiCollection<SentimentKpiPoint[]>({
    speechId: speechConfig?.speechId ?? '',
    kpiName: 'Sentiment',
    initialState: [],
  });

  const sentiments = useMemo(() => {
    const allSentiments: Sentiment[] = [];
    sentimentKpi.forEach(item => {
      const { positive, negative } = item.value;

      positive.forEach(keyword => {
        allSentiments.push({ date: item.date, keyword, sentiment: 'positive' });
      });

      negative.forEach(keyword => {
        allSentiments.push({ date: item.date, keyword, sentiment: 'negative' });
      });
    });

    // Filter out objects with the same keyword and sort the combined array by date
    return allSentiments
      .filter((value, index, self) => self.findIndex(t => t.keyword === value.keyword) === index)
      .sort((a, b) => b.date - a.date);
  }, [sentimentKpi]);

  if (sentimentKpi.length === 0) {
    return <ListeningView />;
  }

  return (
    <div className="flex flex-wrap gap-2 pt-2.5">
      <AnimatePresence>
        {sentiments.map((sentiment, i) => (
          <motion.span
            layout
            key={i}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.5 }}
            className={classNames(
              'rounded-full bg-white px-3.5 py-1.5 text-2xl font-bold',
              sentiment.sentiment === 'positive' ? 'text-eidraGreen' : 'text-eidraRed',
            )}>
            {sentiment.keyword}
          </motion.span>
        ))}
      </AnimatePresence>
    </div>
  );
}
