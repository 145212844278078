import { useContext } from 'react';
import { KioskModeContext, KioskModeContextType } from '../providers/kioskMode';

export const useKioskMode = (): KioskModeContextType => {
  const context = useContext(KioskModeContext);
  if (context === undefined) {
    throw new Error('useKioskMode must be used within a KioskModeProvider');
  }
  return context;
};
