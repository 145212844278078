import { useContext, useEffect, useState } from 'react';
import { AnalysisCarousel } from './AnalysisCarousel';
import { AnalysisCarouselSpeaker } from './AnalysisCarouselSpeaker';
import { SpeechConfigContext } from '../providers/speechConfig';
import { useLocation } from 'react-router-dom';
import IsRecordingMarquee from './IsRecordingMarquee';
import NotStartedView from './NotStartedView';
import { useCountdown } from '../hooks/use-countdown';
import { isRecording } from '../utils/elapsedTime';

interface SpeechAnalysisProps {
  onLoad: (speechId: string | null) => void;
}

export const SpeechAnalysis = ({ onLoad }: SpeechAnalysisProps) => {
  const speechConfig = useContext(SpeechConfigContext);
  const [speechConfigId, setSpeechConfigId] = useState<string | null>(null);
  const { pathname } = useLocation();

  const countdown = useCountdown(speechConfig?.eventStartTime ?? '');

  useEffect(() => {
    if (pathname !== '/') {
      const speechId = pathname.split('/')[1];
      setSpeechConfigId(speechId);
      onLoad(speechId);
    } else {
      setSpeechConfigId(null);
      onLoad(null);
    }
  }, [onLoad, pathname]);

  const hasStarted = !!speechConfig && new Date(speechConfig.eventStartTime).getTime() < Date.now();
  const recording = isRecording(speechConfig);

  return (
    <>
      <div className="relative mx-auto flex h-fallback-screen flex-col lg:max-w-2xl">
        <AnalysisCarouselSpeaker />
        {recording && <IsRecordingMarquee />}
        {speechConfig && speechConfig.speechId === speechConfigId && (
          <main className="block max-h-full flex-grow">
            {hasStarted ? <AnalysisCarousel /> : <NotStartedView countdown={countdown} />}
          </main>
        )}
      </div>
    </>
  );
};
