import Marquee from 'react-fast-marquee';

function IsRecordingMarquee() {
  return (
    <div className="absolute left-3 right-3 top-[8.75rem] bg-eidraDarkCream">
      <Marquee className="text-sm font-medium text-eidraBlack">
        Analys pågår · Analys pågår · Analys pågår · Analys pågår · Analys pågår · Analys pågår · Analys pågår · Analys
        pågår ·
      </Marquee>
    </div>
  );
}

export default IsRecordingMarquee;
