import { useState, useEffect } from 'react';
import { onSnapshot, doc } from 'firebase/firestore';
import { db } from '../firebase';

type FirestoreDocumentHook<T> = {
  data: T | null;
  loading: boolean;
  error: Error | null;
};

export const useDocument = <T>(docPath: string): FirestoreDocumentHook<T> => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const documentRef = doc(db, docPath);
    const unsubscribe = onSnapshot(
      documentRef,
      snapshot => {
        if (snapshot.exists()) {
          setData(snapshot.data() as T);
        } else {
          setData(null);
        }
        setLoading(false);
      },
      err => {
        setError(err);
        setLoading(false);
      },
    );

    return () => unsubscribe();
  }, [db, docPath]);

  return { data, loading, error };
};
