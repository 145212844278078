export const ChevronRight = () => {
  return (
    <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.364031 2.12132L6.72799 8.48528L0.364033 14.8492L2.48535 16.9706L8.84931 10.6066L10.9706 8.48528L8.84931 6.36396L2.48535 0L0.364031 2.12132Z"
        fill="black"
      />
    </svg>
  );
};
