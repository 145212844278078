import React from 'react';
import { SummaryKpiPoint } from '../../../functions/src/common/types/kpiTypes';

export const SummaryStatsView = ({ summaryKpiPoint }: { summaryKpiPoint: SummaryKpiPoint }) => {
  const smallValues = [
    { title: 'Ord per mening', value: summaryKpiPoint.avgWordsPerSentence.toFixed(1) },
    { title: 'Unika ord/100 ord', value: summaryKpiPoint.avgUniqueWordsPerHundred.toFixed(0) },
    { title: 'Valfläsk', value: summaryKpiPoint.promiseCount.toString() },
    { title: 'Floskler', value: summaryKpiPoint.verbiageCount.toString() },
  ];

  const largeValues = [
    { title: 'Favoritområde', value: summaryKpiPoint.mainTheme },
    { title: 'Favoritord', value: summaryKpiPoint.mainKeyword },
  ];
  return (
    <div className="mt-10 space-y-10 px-1">
      <div className="grid grid-cols-2 gap-y-10">
        {smallValues.map(value => (
          <SummaryStatsItem {...value} key={value.title} />
        ))}
      </div>
      <div className="grid grid-cols-1 gap-y-10">
        {largeValues.map(value => (
          <SummaryStatsItem {...value} key={value.title} />
        ))}
      </div>
    </div>
  );
};

const SummaryStatsItem = ({ title, value }: { title: string; value: string }) => {
  return (
    <div className="flex flex-col gap-1">
      <span className="leading-none">{title}</span>
      <strong className="text-[1.875rem] leading-none text-partyBgColor">{value}</strong>
    </div>
  );
};
