import { useContext, useState, useEffect } from 'react';
import { NarrativeTextValue, NarrativeKpiPoint } from '../../../functions/src/common/types/kpiTypes';
import { useKpiCollection } from '../hooks/useKpiCollection';
import { SpeechConfigContext } from '../providers/speechConfig';
import ListeningView from './ListeningView';

export const NarrativeView = () => {
  const speechConfig = useContext(SpeechConfigContext);
  const [positiveDistribution, setPositiveDistribution] = useState<number>(50);
  const [latestNarrative, setLatestNarrative] = useState<NarrativeTextValue | null>(null);

  const [narrativeKpis] = useKpiCollection<NarrativeKpiPoint[]>({
    speechId: speechConfig?.speechId ?? null,
    kpiName: 'Narrative',
    initialState: [] as NarrativeKpiPoint[],
  });

  useEffect(() => {
    const newLatestNarrative = narrativeKpis.at(-1)?.value ?? null;

    if (!newLatestNarrative) {
      return;
    }
    setPositiveDistribution(
      (newLatestNarrative.positiveStatements.length /
        (newLatestNarrative.positiveStatements.length + newLatestNarrative.negativeStatements.length)) *
        100,
    );
    setLatestNarrative(newLatestNarrative);
  }, [narrativeKpis]);

  if (!latestNarrative) {
    return <ListeningView />;
  }

  return (
    <div className="flex h-full flex-col gap-y-1 px-1 py-4 transition-all">
      {positiveDistribution > 0 && (
        <div
          className="flex items-end rounded-t-[3.125rem] bg-eidraLightGreen p-5 transition-all duration-1000"
          style={{ flexBasis: `${positiveDistribution}%` }}>
          <span className="text-[1.125rem] font-medium leading-[1.2]">{latestNarrative.positive}</span>
        </div>
      )}
      {latestNarrative.negative && (
        <div className="flex-auto rounded-b-[3.125rem] bg-eidraRaspberry p-5 transition-all duration-1000">
          <span className="text-[1.125rem] font-medium leading-[1.2]">{latestNarrative.negative}</span>
        </div>
      )}
    </div>
  );
};
