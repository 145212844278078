import { useContext } from 'react';
import { SpeechConfigContext } from '../providers/speechConfig';
import { useCountdown } from '../hooks/use-countdown';
import { formatDate } from '../utils/dates';

interface NotStartedViewProps {
  countdown: ReturnType<typeof useCountdown>;
}

const NotStartedView = ({ countdown }: NotStartedViewProps) => {
  const speechConfig = useContext(SpeechConfigContext);

  return (
    <div className="h-100">
      <div className="flex h-20 items-center px-2.5 text-eidraBlack">
        <h2 className={'mt-1 text-[2.5rem] font-bold leading-none'}>Nedräkning</h2>
      </div>
      <div className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
        <div className="relative w-full overflow-hidden lg:overflow-visible">
          <BackgroundGraphic className="-ml-16 w-[150%] lg:w-full xl:w-[150%]" />
        </div>
      </div>
      <div className="absolute left-1/2 top-1/2 w-4/5 -translate-x-1/2 -translate-y-1/2 text-center text-eidraBlack">
        <div className="text-sm">{countdown.hours > 24 ? 'Talet börjar om' : 'Talet börjar strax'}</div>
        <div className="pt-1 text-[3.5rem] font-bold leading-none">
          {countdown.hours > 24 && !!speechConfig
            ? formatDate(new Date(speechConfig.eventStartTime))
            : countdown.countdownString}
        </div>
      </div>
    </div>
  );
};

export default NotStartedView;

function BackgroundGraphic({ className }: { className: string }) {
  return (
    <svg className={className} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 531 531">
      <circle opacity=".1" cx="265.278" cy="265.278" r="265.278" fill="#757575" />
      <circle opacity=".15" cx="215.984" cy="251.043" r="178.125" fill="#757575" />
      <circle opacity=".15" cx="209.021" cy="279.167" r="133.333" fill="#757575" />
      <circle opacity=".2" cx="194.096" cy="307.292" r="101.042" fill="#757575" />
      <circle opacity=".3" cx="178.473" cy="335.416" r="68.403" fill="#757575" />
      <circle cx="170.48" cy="351.043" r="50.347" fill="#757575" />
    </svg>
  );
}
