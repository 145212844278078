import { intervalToDuration } from 'date-fns';
import type { SpeechConfig } from '../../../functions/src/common/types/speechConfig';

export const getElapsedTime = (start: Date, end: Date) => {
  const duration = intervalToDuration({
    start: start,
    end: end,
  });
  return `${String(duration.minutes ?? 0).padStart(2, '0')}:${String(duration.seconds ?? 0).padStart(2, '0')}`;
};

export const isRecording = (speechConfig?: SpeechConfig | null) => {
  return !!speechConfig && !speechConfig.speechEndTime && !!speechConfig.speechStartTime;
};

export const isRecordingDone = (speechConfig?: SpeechConfig | null) => {
  return !!speechConfig && !!speechConfig.speechEndTime && !!speechConfig.speechStartTime;
};
