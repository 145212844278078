import { useState, useEffect } from 'react';
import { collection, onSnapshot, QuerySnapshot, DocumentData } from 'firebase/firestore';
import { db } from '../firebase';

interface CollectionSnapshotOptions<T> {
  collectionPath: string;
  initialState: T;
}

const useCollectionSnapshot = <T>({ collectionPath, initialState }: CollectionSnapshotOptions<T>) => {
  const [data, setData] = useState<T>(initialState);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const colRef = collection(db, collectionPath);
    const unsubscribe = onSnapshot(
      colRef,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const documents = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setData(documents as T);
        setLoading(false);
      },
      err => {
        setError(err);
        setLoading(false);
      },
    );

    return () => unsubscribe();
  }, [collectionPath]);

  return { data, loading, error };
};

export default useCollectionSnapshot;
