import { type ReactNode } from 'react';
import { EidraLogo } from './EidraLogo';

interface CornerStylingProps {
  children: ReactNode;
}

export function CornerStyling({ children }: CornerStylingProps) {
  return (
    <>
      <div className="pointer-events-none fixed inset-2.5 z-20">
        <div className="absolute size-1.5 rounded-full bg-eidraBlack" />
        <div className="absolute right-0 top-0 size-1.5 rounded-full bg-eidraBlack" />
        <div className="absolute bottom-0 size-1.5 rounded-full bg-eidraBlack" />
        <span className="absolute bottom-0 right-0 w-16 text-eidraBlack">
          <a href="https://www.eidra.com/" target="_blank" rel="noreferrer noopener" className="pointer-events-auto">
            <EidraLogo />
          </a>
        </span>
      </div>
      {children}
    </>
  );
}
