import { useState, useEffect } from 'react';
import { DocumentData, QuerySnapshot, collection, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';

interface KpiDocumentOptions<T> {
  speechId: string | null;
  kpiName: string;
  initialState: T;
}

export const useKpiCollection = <T>({
  speechId,
  kpiName,
  initialState,
}: KpiDocumentOptions<T>): [T, boolean, Error | null] => {
  const [data, setData] = useState<T>(initialState);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!speechId) {
      return;
    }

    const collectionPath = `speeches/${speechId}/${kpiName}`;
    const colRef = collection(db, collectionPath);
    const unsubscribe = onSnapshot(
      colRef,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const documents = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setData(documents as T);
        setLoading(false);
      },
      err => {
        setError(err);
        setLoading(false);
      },
    );

    return () => unsubscribe();

    // const docRef = doc(db, `speeches/${speechId}/kpis`, kpiName);

    // const unsubscribe = onSnapshot(
    //     docRef,
    //     (doc) => {
    //         if (doc.exists()) {
    //             setData(doc.data().kpi as T);
    //         } else {
    //             setData(initialState);
    //         }
    //         setLoading(false);
    //     },
    //     (err) => {
    //         setError(err);
    //         setLoading(false);
    //     }
    // );

    // return () => unsubscribe();
  }, [speechId, kpiName]);

  return [data, loading, error];
};
