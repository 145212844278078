import { useEffect, useState } from 'react';
import { getDateDifferenceInSeconds } from '../utils/dates';

export function useCountdown(countTo: Date | number | string) {
  const [countdownSeconds, setCountdownSeconds] = useState(0);

  useEffect(() => {
    setCountdownSeconds(getDateDifferenceInSeconds(new Date(), new Date(countTo)));

    const interval = setInterval(() => {
      setCountdownSeconds(curr => curr - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [countTo]);

  const hours = Math.floor(countdownSeconds / 60 / 60);
  const minutes = Math.floor((countdownSeconds - hours * 60 * 60) / 60);
  const seconds = Math.round(countdownSeconds - hours * 60 * 60 - minutes * 60);

  const stringParts = hours ? [hours, minutes, seconds] : [minutes, seconds];
  const formattedString = stringParts.map(part => part.toString().padStart(2, '0')).join(':');
  return {
    countdownString: formattedString,
    hours,
    minutes,
    seconds,
  };
}
