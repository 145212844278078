import classNames from 'classnames';
import { ReactNode } from 'react';

interface ParagraphProps {
  children: ReactNode;
  className?: string;
}

export const Paragraph = ({ children, className }: ParagraphProps) => {
  return <p className={classNames('leading-snug', className)}>{children}</p>;
};
