import { useContext } from 'react';
import { SpeechConfigContext } from '../providers/speechConfig';
import { Button } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';

export const AnalysisCarouselSpeaker = () => {
  const speechConfig = useContext(SpeechConfigContext);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    console.log('CLICKED');
    navigate('/');
  };

  return (
    <div className="absolute left-3 right-3 top-20 text-eidraBlack">
      {!!speechConfig && (
        <div className="flex flex-row justify-between border-y-2 border-eidraBlack p-2.5">
          <div className="flex flex-row">
            <img src={speechConfig.partyIcon} alt="Party logo" className="size-8" />
            <div className="ml-2.5 truncate">
              <div className="text-lg font-medium leading-5">{speechConfig.speaker}</div>
              <div className="text-xs font-light">{speechConfig.party}</div>
            </div>
          </div>
          <Button
            pill
            onClick={() => handleButtonClick()}
            className="pointer-events-auto z-50 w-20 bg-eidraBlack text-eidraWhite hover:text-eidraDarkCream">
            Hem
          </Button>
        </div>
      )}
    </div>
  );
};
