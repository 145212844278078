import classNames from 'classnames';
import { ReactNode } from 'react';

interface LayoutProps {
  children: ReactNode;
  className?: string;
}

export const Layout = ({ children, className }: LayoutProps) => {
  return <div className={classNames('p-4', className)}>{children}</div>;
};
