export function EidraLogo() {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 18">
      <path
        d="M0 .371h10.847v3.363H3.774V7.32h6.192v3.066H3.774v3.931h7.073v3.363H0V.37ZM12.156 2.053A2.06 2.06 0 0 1 14.219 0c1.158 0 2.113.94 2.113 2.053 0 1.138-.957 2.052-2.113 2.052a2.046 2.046 0 0 1-2.063-2.052Zm.275 3.362h3.624v12.263H12.43V5.415ZM17.29 11.548c0-3.734 2.768-6.453 6.114-6.453 1.686 0 3.145.668 4.026 1.928V.371h3.624V17.68H27.43v-1.608C26.626 17.332 25.09 18 23.404 18c-3.348 0-6.115-2.72-6.115-6.452Zm10.19-.026c0-1.83-1.433-3.288-3.271-3.288-1.812 0-3.246 1.458-3.246 3.314 0 1.855 1.434 3.313 3.246 3.313 1.838 0 3.271-1.484 3.271-3.34ZM32.715 5.415h3.623v2.152c.654-1.435 1.838-2.152 3.674-2.152h2.77v3.313h-3.045c-2.19 0-3.399 1.237-3.399 3.462v5.488h-3.623V5.415ZM43.236 11.548c0-3.734 2.769-6.453 6.115-6.453 1.66 0 3.145.668 4.025 1.928V5.417H57V17.68h-3.624v-1.608C52.57 17.332 51.012 18 49.351 18c-3.348 0-6.115-2.72-6.115-6.452Zm10.19-.026c0-1.83-1.433-3.288-3.271-3.288-1.812 0-3.245 1.458-3.245 3.314 0 1.855 1.433 3.313 3.245 3.313 1.838 0 3.272-1.484 3.272-3.34Z"
        fill="currentColor"
      />
    </svg>
  );
}
