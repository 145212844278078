import { useEffect, useState } from 'react';
import { SpeechConfig } from '../../functions/src/common/types/speechConfig';
import { SpeechTheme } from './components/SpeechTheme';
import { KioskModeProvider } from './providers/kioskMode';
import { SpeechConfigContext } from './providers/speechConfig';
import { SpeechAnalysis } from './components/SpeechAnalysis';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import useCollectionSnapshot from './hooks/useCollectionSnapshot';
import { CornerStyling } from './components/CornerStyling';

function App() {
  const [speechId, setSpeechId] = useState<string | null>(null);
  const [selectedSpeechConfig, setSelectedSpeechConfig] = useState<SpeechConfig | null>(null);

  const { data: speechConfigs } = useCollectionSnapshot<SpeechConfig[]>({
    collectionPath: `speeches`,
    initialState: [] as SpeechConfig[],
  });

  useEffect(() => {
    console.log('speechId:', speechId);
    if (speechId) {
      const speechConfig = speechConfigs.find((speechConfig: SpeechConfig) => speechConfig.speechId === speechId);
      setSelectedSpeechConfig(speechConfig ? { ...speechConfig } : null);
    } else {
      setSelectedSpeechConfig(null);
    }
  }, [speechId, speechConfigs]);

  return (
    <SpeechConfigContext.Provider value={selectedSpeechConfig}>
      <SpeechTheme speechConfig={selectedSpeechConfig}>
        <CornerStyling>
          <Router>
            <KioskModeProvider>
              <Routes>
                <Route path="/" element={<LandingPage speechConfigs={speechConfigs} />} />
                {speechConfigs.map(speech => (
                  <Route
                    key={speech.speechId}
                    path={`/${speech.speechId}`}
                    element={<SpeechAnalysis onLoad={(speechId: string | null) => setSpeechId(speechId)} />}
                  />
                ))}
              </Routes>
            </KioskModeProvider>
          </Router>
        </CornerStyling>
      </SpeechTheme>
    </SpeechConfigContext.Provider>
  );
}

export default App;
