import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import type { ChartOptions } from 'chart.js';
import { useKioskMode } from '../hooks/useKiosk';

ChartJS.register(ArcElement, Tooltip, Legend);

type Props = {
  labels: string[];
  data: number[];
  dataDescriptor: string;
  title?: string;
};

type ThemeColor = {
  key: string;
  value: string;
  theme: string;
};

const CHART_COLORS = [
  {
    key: 'eidraYellow',
    value: '#FFA207',
    theme: 'Ekonomi',
  },
  {
    key: 'eidraRaspberry',
    value: '#FA8071',
    theme: 'Kultur och civilsamhälle',
  },
  {
    key: 'eidraOrange',
    value: '#FF6D00',
    theme: 'Integration och migration',
  },
  {
    key: 'eidraLightBlue',
    value: '#76A1FF',
    theme: 'Miljö och klimat',
  },
  {
    key: 'eidraLightGreen',
    value: '#8EE594',
    theme: 'Jobb och företagande',
  },
  {
    key: 'eidraGrey',
    value: '#E0E0E0',
    theme: 'Skola och utbildning',
  },
  {
    key: 'eidraMidBlue',
    value: '#013D8F',
    theme: 'Vård och omsorg',
  },
  {
    key: 'eidraBlue',
    value: '#0A64FA',
    theme: 'Lag och ordning',
  },
  {
    key: 'eidraDarkGreen',
    value: '#032A23',
    theme: 'Försvar och säkerhet',
  },
] as ThemeColor[];

// function that returns the colors sorted after themes, equivalent to the order of the themes in the props.data array
const getColors = (data: string[]): string[] => {
  const colors = data.map(theme => CHART_COLORS.find(color => color.theme === theme)?.value);
  return colors as string[];
};

const PieChart = (props: Props) => {
  const { isKioskMode } = useKioskMode();

  const scaleFactor = isKioskMode ? 2 : 1;
  const options: ChartOptions<'pie'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        position: 'top' as const,
        align: 'start' as const,
        labels: {
          // This more specific font property overrides the global property
          font: {
            size: 16 * scaleFactor,
            family: 'ProjectX',
            weight: 'bold',
          },
          color: 'black',
          usePointStyle: true,
          textAlign: 'left' as const,
          padding: 16 * scaleFactor,
        },
      },
    },
    layout: {
      padding: {
        top: 12 * scaleFactor,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
  } as ChartOptions<'pie'>;
  const data = {
    labels: props.labels,
    datasets: [
      {
        label: props.dataDescriptor,
        data: props.data,
        backgroundColor: getColors(props.labels),
        borderColor: getColors(props.labels),
        borderWidth: 1,
      },
    ],
  };
  return <Pie data={data} options={options} />;
};

export default PieChart;
