import { CSSProperties, type ReactNode } from 'react';
import { SpeechConfig } from '../../../functions/src/common/types/speechConfig';

interface SpeechThemeProps {
  children: ReactNode;
  speechConfig: SpeechConfig | null;
}

export function SpeechTheme({ children, speechConfig }: SpeechThemeProps) {
  const themeCssVars = speechConfig
    ? {
        '--party-color-text': speechConfig?.partyColorText,
        '--party-color-background': speechConfig?.partyColorBackground,
      }
    : undefined;
  return (
    <>
      <div style={themeCssVars as CSSProperties}>{children}</div>
    </>
  );
}
