import { useState, useEffect } from 'react';

export const useDidMount = () => {
  const [didMount, setDiDMount] = useState(false);

  useEffect(() => {
    setDiDMount(true);
  }, []);

  return didMount;
};
