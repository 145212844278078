import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface KioskModeContextType {
  isKioskMode: boolean;
}

export const KioskModeContext = createContext<KioskModeContextType | undefined>(undefined);

export const KioskModeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const [isKioskMode, setIsKioskMode] = useState<boolean>(false);

  useEffect(() => {
    const isKiosk = searchParams.get('kiosk') === 'true';
    setIsKioskMode(isKiosk);

    // Add or remove the class on the html tag
    const htmlElement = document.documentElement;
    if (isKiosk) {
      htmlElement.classList.add('kiosk-mode');
    } else {
      htmlElement.classList.remove('kiosk-mode');
    }
  }, [searchParams]);

  return <KioskModeContext.Provider value={{ isKioskMode }}>{children}</KioskModeContext.Provider>;
};
