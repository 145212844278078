import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { connectFirestoreEmulator } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  debug_mode: false,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const app = firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

if (process.env.REACT_APP_FIRESTORE_EMULATOR) {
  connectFirestoreEmulator(db, 'localhost', 8080);
}

export { db };
