import { ReactNode, useRef } from 'react';
import '../styles/simpleListeningAnimation.scss';

export const SimpleListeningAnimation = () => {
  const animation = useRef<HTMLDivElement>(null);

  const bars: ReactNode[] = [];

  for (let i = 0; i < 20; i++) {
    bars.push(
      <div key={i} className={'bar'} style={{ animationDuration: `${Math.random() * (1.8 - 0.6) + 0.6}s` }}></div>,
    );
  }

  return (
    <div className="listening-animation" ref={animation}>
      {bars.map(x => x)}
    </div>
  );
};
